<template>
  <SwitchGroup
    as="div"
    class="flex"
    :class="[labelPosition === 'top' ? 'flex-col-reverse items-start gap-1.5' : 'items-center']"
    @click.stop
  >
    <SwitchEl
      :model-value="value"
      :disabled="isDisabled || $attrs.readonly === true"
      :class="[
        'relative inline-flex flex-shrink-0 rounded-full transition-colors ease-in-out duration-200 focus:outline-none peer',
        size === 'sm' ? 'h-[19px] w-[35px] border-[3px]' : 'h-[26px] w-[47px] border-4',
        value ? truthyValueColor : falsyValueColor,
        isDisabled || $attrs.readonly === true ? 'cursor-default' : 'cursor-pointer',
        labelPosition === 'left' ? 'order-2' : ''
      ]"
      @update:model-value="onChange"
    >
      <span
        aria-hidden="true"
        :class="[
          'pointer-events-none inline-block  rounded-full bg-white transform ring-0 transition ease-in-out duration-200',
          value ? (size === 'sm' ? 'translate-x-4' : 'translate-x-5') : 'translate-x-0',
          size === 'sm' ? 'w-[13px] h-[13px]' :'w-[18px] h-[18px]'
        ]"
      />
    </SwitchEl>
    <div class="flex items-center gap-2">
      <SwitchLabel
        v-if="label"
        as="span"
        :class="labelPositionClasses"
      >
        <span
          :class="[
            'text-xs font-semibold text-text-primary',
            $attrs.required || isRequired ? `after:content-['*']` : ''
          ]"
        >{{ label }}</span>
      </SwitchLabel>
      <Tooltip
        v-if="hints.tooltip"
      >
        <QuestionMarkOutline class="w-4 h-4 text-slate-500" />
        <template #content>
          <p class="text-sm whitespace-pre-line">
            {{ hints.tooltip }}
          </p>
        </template>
      </Tooltip>
    </div>

    <ConfirmModal
      v-if="uncheckConfirm"
      :title="uncheckConfirmOptions?.title"
      :confirm-button-text="uncheckConfirmOptions?.confirmButtonText"
      :cancel-button-text="uncheckConfirmOptions?.cancelButtonText"
      :confirm-button-appearance="uncheckConfirmOptions?.confirmButtonAppearance"
      :icon="uncheckConfirmOptions?.icon"
      :icon-appearance="uncheckConfirmOptions?.iconAppearance"
      :open="uncheckShowConfirm"
      @confirm="uncheckOnConfirm"
      @close="uncheckOnCloseConfirm"
    >
      <component
        :is="uncheckConfirmOptions?.contents"
        v-if="uncheckConfirmOptions?.contents"
      />
      <slot name="uncheck-confirm-modal-text" />
    </ConfirmModal>
    <ConfirmModal
      v-if="checkConfirm"
      :title="checkConfirmOptions?.title"
      :confirm-button-text="checkConfirmOptions?.confirmButtonText"
      :cancel-button-text="checkConfirmOptions?.cancelButtonText"
      :confirm-button-appearance="checkConfirmOptions?.confirmButtonAppearance"
      :icon="checkConfirmOptions?.icon"
      :icon-appearance="checkConfirmOptions?.iconAppearance"
      :open="checkShowConfirm"
      @confirm="checkOnConfirm"
      @close="checkOnCloseConfirm"
    >
      <component
        :is="checkConfirmOptions?.contents"
        v-if="checkConfirmOptions?.contents"
      />
      <slot name="check-confirm-modal-text" />
    </ConfirmModal>
  </SwitchGroup>
</template>

<script lang="ts">
import { Switch as SwitchEl, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { PropType, computed, defineComponent, ref } from 'vue'

import useConfirm, { ConfirmOptions } from '@/composables/useConfirm'

import ConfirmModal from '@/components/Modal/Confirm.vue'
import Tooltip from '@/components/Tooltip/Tooltip.vue'

import QuestionMarkOutline from '../Icons/QuestionMarkOutline.vue'

import { FormInputHints } from '.'

const getColorSet = (baseColor: string) => {
  const classes = []

  switch (baseColor) {
    case 'red':
      classes.push(
        'bg-red-500', 'border-red-500',
        'hover:bg-red-500', 'hover:border-red-500',
        'focus:bg-red-500', 'focus:border-red-500',
        'disabled:bg-red-300', 'disabled:border-red-300'
      )
      break
    case 'green':
      classes.push(
        'bg-green-500', 'border-green-500',
        'hover:bg-green-500', 'hover:border-green-500',
        'focus:bg-green-500', 'focus:border-green-500',
        'disabled:bg-green-200', 'disabled:border-green-200'
      )
      break
    case 'gray':
      classes.push(
        'bg-gray-700', 'border-gray-700',
        'hover:bg-gray-700', 'hover:border-gray-700',
        'focus:bg-gray-700', 'focus:border-gray-700',
        'disabled:bg-gray-400', 'disabled:border-gray-400'
      )
      break
    case 'grayLight':
      classes.push(
        'bg-gray-500', 'border-gray-500',
        'hover:bg-gray-500', 'hover:border-gray-500',
        'focus:bg-gray-500', 'focus:border-gray-500',
        'disabled:bg-gray-300', 'disabled:border-gray-300'
      )
      break
    case 'primary':
      classes.push(
        'bg-primary-500', 'border-primary-500',
        'hover:bg-primary-500', 'hover:border-primary-500',
        'focus:bg-primary-500', 'focus:border-primary-500',
        'disabled:bg-gray-200', 'disabled:border-gray-200'
      )
      break
  }

  return classes.join(' ')
}

export default defineComponent({
  components: {
    SwitchEl,
    SwitchGroup,
    SwitchLabel,
    Tooltip,
    ConfirmModal,
    QuestionMarkOutline
  },
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    labelPosition: {
      type: String as PropType<'top' | 'right' | 'left'>,
      required: false,
      default: () => 'right'
    },
    labelClasses: {
      type: [String, Array] as PropType<string | string[]>,
      required: false,
      default: () => ''
    },
    name: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: false,
      default: true
    },
    checked: {
      type: Boolean,
      required: false,
      default: undefined
    },
    values: {
      type: Object as PropType<{ checked: boolean | string | number, unchecked: boolean | string | number } | undefined>,
      required: false,
      default: undefined
    },
    truthyColor: {
      type: String,
      required: false,
      default: 'green'
    },
    falsyColor: {
      type: String,
      required: false,
      default: 'grayLight'
    },
    hints: {
      type: Object as PropType<FormInputHints>,
      required: false,
      default: () => ({})
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String as PropType<'sm'| 'base'>,
      required: false,
      default: 'base'
    },
    uncheckConfirm: {
      type: Boolean,
      default: false
    },
    uncheckConfirmOptions: {
      type: Object as PropType<ConfirmOptions | undefined>,
      required: false,
      default: undefined
    },
    checkConfirm: {
      type: Boolean,
      default: false
    },
    checkConfirmOptions: {
      type: Object as PropType<ConfirmOptions | undefined>,
      required: false,
      default: undefined
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit, attrs }) {
    const value = computed({
      get () {
        if (props.checked !== undefined) {
          return props.checked
        }
        if (props.values) {
          return props.modelValue === props.values.checked
        }
        return props.modelValue
      },
      set (value: any) {
        if (props.values) {
          emit('update:modelValue', value ? props.values.checked : props.values.unchecked)
          return
        }
        emit('update:modelValue', value)
      }
    })
    const pendingValue = ref(value.value)

    const truthyValueColor = getColorSet(props.truthyColor)

    const falsyValueColor = getColorSet(props.falsyColor)

    const labelPositionClasses = computed(() => {
      const classes = [
        'inline-flex'
      ]

      classes.push(props.isDisabled || attrs.readonly === true ? 'cursor-default' : 'cursor-pointer')

      switch (props.labelPosition) {
        case 'top':
          classes.push('leading-none', 'ml-1')
          break
        case 'left':
          classes.push('order-1 mr-2')
          break
        case 'right':
          classes.push('ml-2')
          break
      }

      if (props.labelClasses) {
        if (Array.isArray(props.labelClasses)) {
          classes.push(...props.labelClasses)
        } else {
          classes.push(props.labelClasses)
        }
      }

      return classes
    })

    const {
      showConfirm: uncheckShowConfirm,
      onClick: uncheckOnClick,
      onConfirm: uncheckOnConfirm,
      onCloseConfirm: uncheckOnCloseConfirm
    } = useConfirm(
      computed(() => props.uncheckConfirm && !pendingValue.value),
      computed(() => () => { value.value = pendingValue.value })
    )

    const {
      showConfirm: checkShowConfirm,
      onClick: checkOnClick,
      onConfirm: checkOnConfirm,
      onCloseConfirm: checkOnCloseConfirm
    } = useConfirm(
      computed(() => !!(props.checkConfirm && pendingValue.value)),
      computed(() => () => { value.value = pendingValue.value })
    )

    const onChange = (value: boolean) => {
      pendingValue.value = value
      if (value) {
        checkOnClick()
      } else {
        uncheckOnClick()
      }
    }

    return {
      value,
      truthyValueColor,
      falsyValueColor,
      labelPositionClasses,

      uncheckShowConfirm,
      uncheckOnConfirm,
      uncheckOnCloseConfirm,

      checkShowConfirm,
      checkOnConfirm,
      checkOnCloseConfirm,

      onChange
    }
  }
})
</script>
