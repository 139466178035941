import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { ContextType } from '@/plugins/context/context.enum'

import { useContextStore } from '@/store/context.store'

import { BidderComputation } from '@/models/bidderNotification'
import { PublisherComputation } from '@/models/publisherNotification'

import { fetchBidderNotifications } from '@/services/bidders'
import { fetchPublisherNotifications } from '@/services/publishers'

const NOTIFICATION_INTERVAL_DELAY = 3 * 60 * 1000
const NOTIFICATION_THROTTLE_DELAY = 1 * 60 * 1000

export const useContextNotificationsStore = defineStore('contextNotifications', () => {
  const contextStore = useContextStore()

  const computation = ref<PublisherComputation>()
  const bidderComputation = ref<BidderComputation>()

  const notificationIntervalId = ref<number>()
  const lastFetchNoticationCallTs = ref<number>()

  const handleFetchNotificationOnVisibilityChange = ref(() => {})

  const publisherWebsiteErrors = computed(() => {
    return computation.value?.websiteErrorCount || 0
  })

  const publisherWebsiteAdstxtErrorCount = computed(() => {
    return computation.value?.websiteAdstxtErrorCount || 0
  })

  const publisherWebsiteSellersjsonErrorCount = computed(() => {
    return computation.value?.websiteSellersjsonErrorCount || 0
  })

  const publisherWebsiteInfoCount = computed(() => {
    return computation.value?.websiteInfoCount || 0
  })

  const publisherWebsiteWarnings = computed(() => {
    return computation.value?.websiteWarningCount || 0
  })

  const publisherWebsiteAdstxt11IssuesCount = computed(() => {
    return computation.value?.websiteAdstxt11IssuesCount || 0
  })

  const publisherSeatsWaiting = computed(() => {
    return computation.value?.seatsWaitingCount || 0
  })

  const publisherSeatsDisconnected = computed(() => {
    return computation.value?.seatsDisconnectedCount || 0
  })

  const publisherSeatsConnected = computed(() => {
    return computation.value?.seatsConnectedCount || 0
  })

  const publisherWhitelistsUnwhitelistedCount = computed(() => {
    return computation.value?.whitelistsUnwhitelistedCount || 0
  })

  const publisherAllErrors = computed(() => {
    return publisherWebsiteErrors.value
  })

  const publisherAllWarnings = computed(() => {
    return publisherWebsiteWarnings.value
  })

  const bidderWhitelistActionRequiredCount = computed(() => {
    return bidderComputation.value?.whitelistActionRequiredCount || 0
  })

  const bidderWhitelistWhitelistedCount = computed(() => {
    return bidderComputation.value?.whitelistWhitelistedCount || 0
  })

  const bidderWhitelistBlockedCount = computed(() => {
    return bidderComputation.value?.whitelistBlockedCount || 0
  })

  const resetPublisherComputation = (id: number): void => {
    (computation.value as Partial<PublisherComputation>) = {
      publisherId: id
    }
  }

  const setComputation = (publisherComputation: PublisherComputation): void => {
    computation.value = { ...computation.value, ...publisherComputation }
  }

  const resetBidderComputation = (id: number): void => {
    (bidderComputation.value as Partial<BidderComputation>) = {
      bidderId: id
    }
  }

  const setBidderComputation = (computation: BidderComputation): void => {
    bidderComputation.value = { ...bidderComputation.value, ...computation }
  }

  const fetchNotifications = async (): Promise<void> => {
    const contextId = contextStore.contextId

    if (!contextId) {
      throw new Error('Context not found')
    }

    try {
      if (contextStore.contextType === ContextType.PUBLISHERS) {
        const response = await fetchPublisherNotifications(contextId)
        resetPublisherComputation(contextStore.contextId)

        if (response?.data?.publisherComputation) {
          setComputation(response.data.publisherComputation)
        }
      } else if (contextStore.contextType === ContextType.BIDDERS) {
        const response = await fetchBidderNotifications(contextId)
        resetBidderComputation(contextStore.contextId)

        if (response?.data?.bidderComputation) {
          setBidderComputation(response.data.bidderComputation)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  const startInterval = (): void => {
    window.document.addEventListener(
      'visibilitychange',
      handleFetchNotificationOnVisibilityChange.value
    )

    const interval = window.setInterval(async () => {
      await fetchNotificationsOnVisibleState()
    }, NOTIFICATION_INTERVAL_DELAY)

    notificationIntervalId.value = interval
  }

  const clearInterval = (): void => {
    window.document.removeEventListener(
      'visibilitychange',
      handleFetchNotificationOnVisibilityChange.value
    )
    window.clearInterval(notificationIntervalId.value)
  }

  const fetchNotificationsOnVisibleState = async (): Promise<void> => {
    if (window.document.hidden === false) {
      const now = Date.now()
      const elapsed = now - (lastFetchNoticationCallTs.value || 0)

      if (isNaN(elapsed) || elapsed >= NOTIFICATION_THROTTLE_DELAY) {
        lastFetchNoticationCallTs.value = now

        await fetchNotifications()
      }
    }
  }

  return {
    computation,
    bidderComputation,

    publisherWebsiteErrors,
    publisherWebsiteWarnings,
    publisherWebsiteInfoCount,
    publisherWebsiteAdstxtErrorCount,
    publisherWebsiteSellersjsonErrorCount,
    publisherWebsiteAdstxt11IssuesCount,
    publisherSeatsWaiting,
    publisherSeatsDisconnected,
    publisherSeatsConnected,
    publisherWhitelistsUnwhitelistedCount,
    publisherAllErrors,
    publisherAllWarnings,
    bidderWhitelistActionRequiredCount,
    bidderWhitelistWhitelistedCount,
    bidderWhitelistBlockedCount,

    fetchNotifications,

    startInterval,
    clearInterval
  }
})
