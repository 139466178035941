import { RouteRecordRaw } from 'vue-router'

import i18n from '@/plugins/i18n'

export function globalConfig (): RouteRecordRaw[] {
  return [
    {
      path: 'dfp',
      name: 'config.dfp',
      component: () => import('./views/DFP.vue'),
      meta: {
        title: i18n.global.t('labels.dfp', 100),
        layout: 'base',
        requireService: true
      }
    }
  ]
}
