import { RouteRecordRaw } from 'vue-router'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function groupSeats (): RouteRecordRaw[] {
  const context = ContextType.GROUPS
  return [
    {
      path: 'seats',
      name: `${context}.seats.dfp-index`,
      component: () => import('./views/IndexDFP.vue'),
      meta: {
        title: i18n.global.t('labels.dynamicfloorprice'),
        feature: 'seats',
        requireService: true
      }
    }
  ]
}
