<template>
  <div
    :class="wrapperClass"
    @click.stop
  >
    <Tooltip
      :disabled="(fieldErrors?.$errors?.length || 0) <= 0"
      :closeable="true"
      :force-show="true"
    >
      <label
        v-if="label"
        :for="idValue"
        class="flex w-full text-xs font-semibold text-text-primary"
      >
        <span
          v-if="label"
          :class="visualMode === 'materialize' ? 'hidden' : 'mb-2'"
        />
      </label>
      <div class="relative flex flex-col">
        <Multiselect
          :id="idValue"
          :key="options"
          ref="multiselectRef"
          v-model="internalValue"
          :allow-absent="allowAbsent"
          :mode="mode"
          :value-prop="valueProp"
          label="name"
          track-by="name"
          :searchable="hasAsyncOptions"
          :delay="asyncOptionsDelay"
          :resolve-on-load="hasAsyncOptions"
          :filterResults="!hasAsyncOptions"
          :can-deselect="!isRequired"
          :can-clear="canClear"
          :close-on-select="mode === 'single'"
          :options="options"
          :classes="computedClasses"
          :disabled="disabled || $attrs.readonly === true"
          :create-option="createOption"
          :add-option-on="['enter', 'space', 'tab', ';', ',']"
          class="[counter-reset:line-number]"
          v-bind="$attrs"
          @select="(event: Event, value: any, option: any) => onInternalEvent(onSelect, event, value, option)"
          @deselect="(event: Event, value: any, option: any) => onInternalEvent(onDeselect, event, value, option)"
          @clear="() => onInternalEvent(onClear)"
          @open="onOpenFn"
          @close="onCloseFn"
          @update:model-value="onUpdateModelValue"
        >
          <template #caret>
            <div
              v-if="canSelectAll && !disabled && $attrs.readonly !== true"
              class="flex self-start justify-end pt-3 whitespace-nowrap"
            >
              <AppButton
                size="xs"
                appearance="clear"
                class="hover:!text-primary-500 !font-normal"
                @click.stop="onSelectAll"
              >
                {{ hasSelected && canClear ? t('actions.deselectAll') : t('actions.selectAll') }}
              </AppButton>
            </div>
            <TooltipInfo
              v-if="hints.tooltip"
              class="ml-1 text-slate-500"
              :message="hints.tooltip"
            />
            <span
              ref="caretRef"
              class="flex items-center justify-center px-1 mr-1"
              aria-hidden="true"
              @click="handleCaretClick"
            >
              <ChevronDownIcon class="w-4 h-4 text-slate-400" />
            </span>
          </template>
          <template
            v-if="canClear"
            #clear="{ clear }"
          >
            <span
              class="z-20 flex items-center justify-center cursor-pointer"
              :class="{ hidden: canSelectAll }"
              aria-hidden="true"
              @click.stop="clear"
            >
              <XMarkIcon class="w-4 h-4 text-slate-400" />
            </span>
          </template>
          <template #option="{ option }">
            <div
              class="flex items-center w-full"
            >
              <div class="w-3 h-3 text-primary-500">
                <CheckIcon v-if="isSelected(option)" />
              </div>
              <div class="flex-1 px-1.5 py-1">
                <slot
                  name="option"
                  :option="option"
                  :is-selected="isSelected(option)"
                  :set-value="setValue"
                >
                  {{ extractLabel(option) }}
                </slot>
              </div>

              <button
                v-if="mode !== 'single'"
                type="button"
                class="p-0.5 px-2 text-xs rounded-sm hover:bg-primary-200 group-hover:block hidden"
                @mousedown.stop="setValue(option)"
              >
                {{ $t('labels.only') }}
              </button>
            </div>
          </template>
          <template
            #tag="{
              option,
              handleTagRemove
            }: {
              option: FormSelectOption,
              handleTagRemove: Function
            }"
          >
            <slot
              name="tag"
              :option="option"
              :handle-tag-remove="handleTagRemove"
              :error="getError(option)"
            >
              <Tooltip
                v-for="(err, i) in [getError(option)]"
                :key="i"
                :disabled="!err"
                :reference-props="{
                  class: [
                    computedClasses?.tag
                      ? computedClasses.tag
                      : 'text-slate-600 text-sm leading-none font-regular bg-gray-300 rounded flex items-center h-4 min-w-0 break-all pl-1.5 pr-1 mx-0.5 my-0.75',
                    {
                      'line-through': option.disabled && !allowDisabledOptions,
                      '!bg-red-200 !text-red-900': err !== undefined
                    }
                  ],
                  'aria-label': option.name,
                  title: option.name
                }"
                placement="top"
              >
                <span
                  class="truncate"
                  :class="showPosition ? counterClass : ''"
                >
                  {{ option.name }}
                </span>
                <span
                  v-if="!disabled && $attrs.readonly !== true"
                  class="flex items-center justify-center p-0.5 ml-0.5 group"
                  @click="handleTagRemove(option, $event)"
                >
                  <span
                    class="inline-block w-2 h-2 bg-center bg-no-repeat bg-multiselect-remove opacity-30 group-hover:opacity-60"
                  />
                </span>
                <template
                  v-if="err"
                  #title
                >
                  <div class="flex flex-col gap-0.5">
                    <p
                      v-for="(message, iM) in err!.$errors"
                      :key="iM"
                      class="text-xs font-normal"
                    >
                      {{ message }}
                    </p>
                  </div>
                </template>
              </Tooltip>
            </slot>
          </template>
        </Multiselect>
        <span
          v-if="visualMode === 'materialize'"
          class="order-1 absolute top-[50%] -mt-[11px] left-[1px] pointer-events-none transition-all flex items-center text-base leading-5.5 text-slate-500 font-semibold border-none peer-focus:text-xs peer-focus:top-[6px] peer-focus:mt-0 peer-focus:leading-4.5"
          :class="[
            (internalValue !== '' && internalValue !== null) || isSelectActive
              ? '!text-xs !top-[6px] !mt-0 !leading-4.5'
              : '',
            disabled || $attrs.readonly === true ? 'bg-gray-200' : 'bg-white',
            $attrs.required || isRequired ? `after:content-['*']` : '',
            compact ? 'px-3' : 'pl-5 pr-2.5'
          ]"
        >
          {{ label }}
        </span>
      </div>
      <template
        v-if="(fieldErrors?.$errors?.length || 0) > 0"
        #title
      >
        <div class="flex flex-col gap-0.5">
          <p
            v-for="(err, i) in fieldErrors!.$errors"
            :key="i"
            class="text-xs font-normal"
          >
            {{ err }}
          </p>
        </div>
      </template>
    </Tooltip>
    <FormHelpText
      v-if="hints.textBottom"
      :help-text="hints.textBottom"
    />
    <ConfirmModal
      v-if="confirm"
      :title="confirmOptions?.title"
      :confirm-button-text="confirmOptions?.confirmButtonText"
      :cancel-button-text="confirmOptions?.cancelButtonText"
      :confirm-button-appearance="confirmOptions?.confirmButtonAppearance"
      :icon="confirmOptions?.icon"
      :open="showConfirm"
      @confirm="onConfirm"
      @close="onCloseConfirm"
    >
      <component
        :is="confirmOptions?.contents"
        v-if="confirmOptions?.contents"
      />
      <slot name="confirm-modal-text" />
    </ConfirmModal>
  </div>
</template>

<script lang="ts">
import { CheckIcon } from '@heroicons/vue/20/solid'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import Multiselect from '@vueform/multiselect'
import { ErrorObject } from '@vuelidate/core'
import { ComputedRef, PropType, computed, defineComponent, onMounted, ref, unref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { FormSelectOption } from '@/types/form'
import { ObjectLike } from '@/types/utils'

import useConfirm, { ConfirmOptions } from '@/composables/useConfirm'

import { useVuelidateError } from '@/plugins/form'

import ConfirmModal from '@/components/Modal/Confirm.vue'
import {
  multiselectTailwindClasses,
  multiselectTailwindClassesCompact
} from '@/components/Multiselect'
import Tooltip from '@/components/Tooltip/Tooltip.vue'

import AppButton from '../Buttons/AppButton.vue'
import TooltipInfo from '../Tooltip/TooltipInfo.vue'

import FormHelpText from './FormHelpText.vue'

import { FormInputHints, FormInputMode } from '.'

type MultiselectValue = FormSelectOption | number | string | null | any[]

export default defineComponent({
  components: {
    Multiselect,
    ChevronDownIcon,
    Tooltip,
    FormHelpText,
    ConfirmModal,
    XMarkIcon,
    AppButton,
    TooltipInfo,
    CheckIcon
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    visualMode: {
      type: String as PropType<FormInputMode>,
      required: false,
      default: 'materialize'
    },
    mode: {
      type: String as PropType<'tags' | 'single' | 'multiple'>,
      required: false,
      default: ''
    },
    options: {
      type: [Array, Object, Function] as PropType<
        | string[]
        | FormSelectOption[]
        | ComputedRef<FormSelectOption[]>
        | ((query: string) => Promise<FormSelectOption[] | undefined>)
        | ((query: ObjectLike) => Promise<FormSelectOption[] | undefined>)
      >,
      required: true
    },
    modelValue: {
      type: [Number, String, Array, Object] as PropType<MultiselectValue>,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    canClear: {
      type: Boolean,
      required: false,
      default: false
    },
    onSelect: {
      type: Function as PropType<(event: Event, value: any, option: any) => void>,
      required: false,
      default: () => {}
    },
    onDeselect: {
      type: Function as PropType<(event: Event, value: any, option: any) => void>,
      required: false,
      default: () => {}
    },
    onChange: {
      type: Function as PropType<(value: any) => void>,
      required: false,
      default: () => {}
    },
    onClear: {
      type: Function as PropType<() => void>,
      required: false,
      default: () => {}
    },
    onOpen: {
      type: Function as PropType<(id: number | string) => void>,
      required: false,
      default: () => {}
    },
    onClose: {
      type: Function as PropType<(value: any, id: number | string) => void>,
      required: false,
      default: () => {}
    },
    wrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    errors: {
      type: [Array] as PropType<ErrorObject[]>,
      required: false,
      default: () => []
    },
    compact: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Object,
      default: () => undefined
    },
    hints: {
      type: Object as PropType<FormInputHints>,
      required: false,
      default: () => ({})
    },
    createOption: {
      type: Boolean,
      required: false,
      default: false
    },
    confirm: {
      // If the function returns true, a confirm modal is shown
      type: Function as PropType<((newVal: MultiselectValue) => boolean) | undefined>,
      required: false,
      default: undefined
    },
    confirmOptions: {
      type: Object as PropType<ConfirmOptions | undefined>,
      required: false,
      default: undefined
    },
    allowDisabledOptions: {
      // Remove the line-through style on selected options that are disabled if true
      type: Boolean,
      required: false,
      default: false
    },
    canSelectAll: {
      type: Boolean,
      default: false
    },
    showPosition: {
      // If true and if the mode is "tags", shows the position of the element in the modelValue inside the element's tag
      type: Boolean,
      default: false
    },
    onlySelector: {
      type: Boolean,
      default: true
    },
    allowAbsent: {
      type: Boolean,
      default: false
    },
    valueProp: {
      type: String,
      default: 'id'
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const multiselectRef = ref<InstanceType<typeof Multiselect> | null>(null)
    const caretRef = ref<HTMLSpanElement>()

    const hasAsyncOptions = computed(() => typeof props.options === 'function')

    const asyncOptionsDelay = computed(() => (hasAsyncOptions.value ? 200 : undefined))

    const value = computed<MultiselectValue>({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
        if (props.onChange) {
          props.onChange(value)
        }
      }
    })
    const internalValue = ref(value.value)

    const idValue = `${props.name}`
    const computedClasses = computed(() => {
      const classes = Object.assign(
        {},
        props.classes
          ? props.classes
          : props.compact
            ? multiselectTailwindClassesCompact
            : multiselectTailwindClasses
      )
      if (hasError.value) {
        classes.container += ' !border-red-400'
      }
      return classes
    })

    const isSelectActive = ref<boolean>(false)

    onMounted(() => {
      // Ensure the caret is not clickable when the multiselect is closed.
      // This class is removed on opening, to allow the user to use it to close.
      caretRef.value?.classList.add('pointer-events-none')
    })

    const onOpenFn = (multiselectInstance: InstanceType<typeof Multiselect>) => {
      isSelectActive.value = true
      caretRef.value?.classList.add('pointer-events-auto')
      if (!multiselectInstance || !multiselectInstance.id) return
      props.onOpen(multiselectInstance.id)
    }

    const onCloseFn = (multiselectInstance: InstanceType<typeof Multiselect>) => {
      isSelectActive.value = false
      caretRef.value?.classList.remove('pointer-events-auto')
      if (!multiselectInstance || !multiselectInstance.id) return
      props.onClose(multiselectInstance.value, multiselectInstance.id)
    }

    const fieldErrors = computed(() => useVuelidateError(props.errors))

    const hasError = computed(
      () => fieldErrors.value && (fieldErrors.value.$errors?.length || fieldErrors.value.$elements)
    )

    const getError = (option: FormSelectOption) => {
      if (!value.value) {
        return undefined
      }
      const i = (value.value as any[]).findIndex((v) => {
        if (typeof v === 'string') {
          return v === option.id
        }
        return v.id === option.id
      })
      if (i === -1 || !fieldErrors.value?.$elements) {
        return undefined
      }
      return fieldErrors.value.$elements[i]
    }

    // We store the last event triggered by the internal multiselect so we can
    // call the callbacks defined in the props only after confirmation.
    let lastEvent: {
      callback: (event: Event, value: any, option: any) => void | (() => void)
      event?: Event
      value?: any
      option?: any
    } | null = null

    const onInternalEvent = (
      callback?: (event: Event, value: any, option: any) => void | (() => void),
      event?: Event,
      value?: any,
      option?: any
    ) => {
      if (callback) {
        lastEvent = {
          callback,
          event,
          value,
          option
        }
      } else {
        lastEvent = null
      }
    }

    // Confirmation
    const onAccept = computed(() => () => {
      value.value = internalValue.value
      if (lastEvent) {
        if (lastEvent.callback.length > 0) {
          lastEvent.callback(lastEvent.event!, lastEvent.value, lastEvent.option)
        } else {
          ;(lastEvent.callback as () => void)()
        }
      }
      lastEvent = null
    })
    const onCancelConfirm = computed(() => () => {
      internalValue.value = value.value
    })
    const { showConfirm, onClick, onConfirm, onCloseConfirm } = useConfirm(
      computed(() => props.confirm !== undefined),
      onAccept,
      onCancelConfirm
    )

    const onUpdateModelValue = () => {
      if (props.confirm ? props.confirm(internalValue.value) : false) {
        onClick()
      } else {
        onAccept.value()
      }
    }

    watch(
      () => props.modelValue,
      () => {
        internalValue.value = value.value
      }
    )

    const isSelected = computed(() => (option: FormSelectOption) => {
      if (!option || !internalValue.value) return false
      if (Array.isArray(internalValue.value)) {
        return internalValue.value.includes(extractValue(option))
      }
      return (typeof option === 'object' ? option.id : option) === internalValue.value
    })

    const extractValue = (option: MultiselectValue) => {
      if (!option) return null
      return typeof option === 'object' && !Array.isArray(option) ? option.id : option
    }

    const extractLabel = (option: MultiselectValue) => {
      if (!option) return null
      return typeof option === 'object' && !Array.isArray(option) ? option.name : option
    }

    const setValue = (option: MultiselectValue) => {
      if (!option) return []
      if (!hasAsyncOptions.value) {
        value.value = [extractValue(option)]
      } else value.value = [option]
    }

    const handleCaretClick = () => {
      if (multiselectRef.value) {
        // Copied for https://github.com/vueform/multiselect/blob/main/src/composables/useMultiselect.js#L87
        multiselectRef.value.deactivate()
        multiselectRef.value.blur()

        caretRef.value?.classList.remove('pointer-events-auto')
      }
    }

    const hasSelected = computed(() => !!multiselectRef.value?.hasSelected)
    const onSelectAll = () => {
      if (hasSelected.value && props.canClear) {
        // VueForm doesn't mention the clear function in it's type declaration, yet the function is exported in the package.
        // Casting to any to avoid typing errors, this will be updated when @vueform fixes the bug.
        (multiselectRef.value as any)?.clear()
      } else {
        multiselectRef.value?.selectAll()
      }
    }

    return {
      t,
      multiselectRef,
      caretRef,
      unref,
      computedClasses,
      internalValue,
      idValue,
      onOpenFn,
      onCloseFn,
      isSelectActive,
      hasAsyncOptions,
      asyncOptionsDelay,
      fieldErrors,
      hasError,
      getError,
      onSelectAll,
      hasSelected,
      isSelected,
      setValue,
      extractLabel,

      showConfirm,
      onConfirm,
      onCloseConfirm,
      onUpdateModelValue,
      onInternalEvent,

      handleCaretClick,
      counterClass: '[counter-increment:line-number] before:[content:counter(line-number)"_-_"]'
    }
  }
})
</script>
