import { FormSelectOptionWithId } from '@/types/form'

import i18n from '@/plugins/i18n'

export enum IntegrationType {
  ADAGIO_CLIENT_ADAPTER = 'adg-pb-clt',
  PBS_CLIENT_ADAPTER = 'pbs',
  ORTB_ADAPTER = 'ortb',
}

export const integrationTypes: Array<FormSelectOptionWithId<string>> = [
  { id: IntegrationType.ADAGIO_CLIENT_ADAPTER, name: i18n.global.t('labels.integrationTypeAdagio') },
  { id: IntegrationType.PBS_CLIENT_ADAPTER, name: i18n.global.t('labels.integrationTypePBS') },
  { id: IntegrationType.ORTB_ADAPTER, name: i18n.global.t('labels.integrationTypeORTB') }
]
