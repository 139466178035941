import i18n from '@/plugins/i18n'

import { CreateAdapterForm } from '@/models/adapter'
import { Adstxt, AdstxtItem, AdstxtItemType, AdstxtStatus, AdstxtWithStatus } from '@/models/adstxt'
import { AdstxtLine, EMPTY_ACCOUNT_ID } from '@/models/adstxtLine'
import { AdstxtOptoutForm } from '@/models/adstxtOptout'
import { AdstxtLineStatus, AdstxtLineType, AdstxtStatusLevel, SellersjsonEntryStatus } from '@/models/enum/adstxt'
import { PublisherRelationship } from '@/models/enum/publisherRelationship'
import { SellerType } from '@/models/enum/sellerType'
import { WebsiteStatus } from '@/models/enum/websiteStatus'
import { FileStatus } from '@/models/file'
import { OptinAdstxt } from '@/models/optinAdstxt'
import { SeatForm } from '@/models/seat'
import { AdstxtOptions, OwnerLine, WebsiteComputation } from '@/models/website'

import { diffDatesLimit, fromNow } from '@/utils/dates'
import { domainContains } from '@/utils/domains'

import { BadgeTheme } from '../Badge/theme'
import { CalloutTheme } from '../Callout/theme'

export const ADSTXT_VERSION_1_0 = '1.0'
export const ADSTXT_VERSION_1_1 = '1.1'
export type AdstxtVersion = typeof ADSTXT_VERSION_1_0 | typeof ADSTXT_VERSION_1_1

/*
  PREFIX:
    P = PUBLISHER
    I = INTERMEDIARY
    BO = BOTH - OWNED
    BM = BOTH - MANAGED

  SUFFIX:
    SD = SINGLE DOMAIN
    MD = MULTIPLE DOMAIN
*/
export enum Adstxt11Category {
  UNKNOWN,
  PSD,
  PMD,
  ISD,
  IMD,
  BOSD,
  BOMD,
  BMSD,
  BMMD
}

export enum SellersjsonType {
  OWNER,
  MANAGER
}

export enum AdstxtPreviewContext {
  DEFAULT,
  OWNER_SETTINGS,
  MANAGER_SETTINGS,
  ADSTXT_11,
}

export interface FileItem {
    status: CalloutTheme
    message: string
}

export function adstxtFileStatus (file: FileStatus | undefined): FileItem {
  let status = CalloutTheme.DANGER
  let message = 'NA'

  if (!file) {
    status = CalloutTheme.DANGER
    message = i18n.global.t('supplyChain.adstxtNotYetCrawled')
  } else if (diffDatesLimit(file.lastDateCheck, file.lastDateOk, 4)) {
    status = CalloutTheme.DANGER
    message = i18n.global.t('supplyChain.adstxtCrawlingError')
  } else if (file.errorCode) {
    status = CalloutTheme.WARNING
    message = i18n.global.t('supplyChain.adstxtCrawlingNotReachable')
  } else if (file.warnings && file.warnings.length > 0) {
    status = CalloutTheme.INFO
    message = i18n.global.t('supplyChain.adstxtCrawlingWarning')
  } else {
    status = CalloutTheme.SUCCESS
    message = i18n.global.t('supplyChain.adstxtCrawlingSuccess')
  }

  return { status, message }
}

export function sellersjsonFileStatus (sellersjsonType: SellersjsonType, category: Adstxt11Category | undefined, file: FileStatus | undefined): FileItem {
  let status = CalloutTheme.DANGER
  let message = 'NA'

  if (!file) {
    status = CalloutTheme.DANGER
    message = i18n.global.t('supplyChain.sellersjsonNotYetCrawled')
  } else if (diffDatesLimit(file.lastDateCheck, file.lastDateOk, 4)) {
    status = CalloutTheme.DANGER
    message = i18n.global.t('supplyChain.sellersjsonCrawlingError')
  } else if (file.errorCode) {
    status = CalloutTheme.WARNING
    message = i18n.global.t('supplyChain.sellersjsonCrawlingNotReachable')
  } else if (file.warnings && file.warnings.length > 0) {
    status = CalloutTheme.INFO
    message = i18n.global.t('supplyChain.sellersjsonCrawlingWarning')
  } else {
    status = CalloutTheme.SUCCESS
    message = i18n.global.t('supplyChain.sellersjsonCrawlingSuccess')
  }

  if (sellersjsonType === SellersjsonType.OWNER &&
        [Adstxt11Category.PMD, Adstxt11Category.IMD, Adstxt11Category.BOSD, Adstxt11Category.BMMD].includes(category!) &&
        status === CalloutTheme.DANGER) {
    status = CalloutTheme.WARNING
  }

  return { status, message }
}

export function adstxtStatus11 (isLoading: Boolean, status: WebsiteStatus): WebsiteStatus {
  if (isLoading) {
    return WebsiteStatus.LOADING
  }
  return status
}

export function adstxtStatus (isLoading: Boolean, options: AdstxtOptions, file: FileStatus, adstxts: AdstxtWithStatus[], websiteComputation: WebsiteComputation): WebsiteStatus {
  let result = WebsiteStatus.VALID

  if (isLoading) {
    result = WebsiteStatus.LOADING
  } else if (options.fetchBypass) {
    result = WebsiteStatus.BYPASSED
  } else if (diffDatesLimit(file.lastDateCheck, file.lastDateOk, 4)) {
    result = WebsiteStatus.ERROR
  } else if (adstxts.some(a => a.status === AdstxtStatus.ERROR)) {
    result = WebsiteStatus.ERROR
  } else if (!adstxts.some(a => a.adstxtLine.adstxtLineType === AdstxtLineType.PRIMARY && [AdstxtStatus.OK, AdstxtStatus.WARNING, AdstxtStatus.INFO].includes(a.status)) && !websiteComputation.hasDSPOptin) {
    result = WebsiteStatus.ERROR
  } else if (adstxts.some(a => a.status === AdstxtStatus.WARNING)) {
    result = WebsiteStatus.WARNING
  } else if (file.errorCode) {
    result = WebsiteStatus.WARNING
  } else if (adstxts.some(a => a.status === AdstxtStatus.INFO)) {
    result = WebsiteStatus.INFO
  } else if (file.warnings && file.warnings.length > 0) {
    result = WebsiteStatus.INFO
  }

  return result
}

export function sellersjsonStatus11 (isLoading: Boolean, status: WebsiteStatus): WebsiteStatus {
  if (isLoading) {
    return WebsiteStatus.LOADING
  }
  return status
}

export function sellersjsonStatus (isLoading: Boolean, file: FileStatus | undefined, sellersjsons: AdstxtWithStatus[]): WebsiteStatus {
  let result = WebsiteStatus.VALID
  if (isLoading) {
    result = WebsiteStatus.LOADING
  } else if (!file) {
    result = WebsiteStatus.ERROR
  } else if (diffDatesLimit(file.lastDateCheck, file.lastDateOk, 4)) {
    result = WebsiteStatus.ERROR
  } else if (sellersjsons.some(s => s.status === AdstxtStatus.ERROR)) {
    result = WebsiteStatus.ERROR
  } else if (sellersjsons.some(s => s.status === AdstxtStatus.WARNING)) {
    result = WebsiteStatus.WARNING
  } else if (file.errorCode) {
    result = WebsiteStatus.WARNING
  } else if (sellersjsons.some(s => s.status === AdstxtStatus.INFO)) {
    result = WebsiteStatus.INFO
  } else if (file.warnings && file.warnings.length > 0) {
    result = WebsiteStatus.INFO
  }

  return result
}

export function aggregateStatus (statusArray: WebsiteStatus[]): WebsiteStatus {
  let result = WebsiteStatus.VALID
  if (statusArray.some(s => s === WebsiteStatus.BYPASSED)) {
    result = WebsiteStatus.BYPASSED
  } else if (statusArray.some(s => s === WebsiteStatus.ERROR)) {
    result = WebsiteStatus.ERROR
  } else if (statusArray.some(s => s === WebsiteStatus.WARNING)) {
    result = WebsiteStatus.WARNING
  } else if (statusArray.some(s => s === WebsiteStatus.INFO)) {
    result = WebsiteStatus.INFO
  }
  return result
}

export function displayLastDateOk (fileStatus : FileStatus | null | undefined): string {
  if (!fileStatus || !fileStatus.lastDateOk) {
    return i18n.global.t('dates.never')
  }
  return fromNow(new Date(fileStatus.lastDateOk))
}

export function displayLastDateCheck (fileStatus : FileStatus | null | undefined): string {
  if (!fileStatus || !fileStatus.lastDateCheck) {
    return i18n.global.t('dates.never')
  }
  return fromNow(new Date(fileStatus.lastDateCheck))
}

export function displayAdstxt11Title (): string {
  return `# ${i18n.global.t('supplyChain.adstxtTitleAdstxt11Lines')}`
}

export function displayAdagioTitle (): string {
  return `# ${i18n.global.t('supplyChain.adstxtTitleAdagio')}`
}

export function displayManagerTitle (): string {
  return `# ${i18n.global.t('supplyChain.adstxtTitleManager')}`
}

export function displaySeatTitle (seatName?: string, status?: AdstxtStatus): string {
  return `# ${seatName}${status === AdstxtStatus.PENDING ? ` - ${i18n.global.t('supplyChain.adstxtTitlePendingBidder')}` : ''}`
}

// Used to display a first section for all the secondary lines shared by multiple seats.
export function displayBidderTitle (bidderName?: string): string {
  if (bidderName) {
    return `# ${bidderName} - ${i18n.global.t('supplyChain.adstxtTitlePartners')}`
  }
  return `# ${i18n.global.t('supplyChain.adstxtTitlePartners')}`
}

export function displayAdstxtLine (line: AdstxtLine): string {
  return `${line.domainName}, ${!line.publisherAccountId?.length ? EMPTY_ACCOUNT_ID : line.publisherAccountId}, ${line.relationship + (line.certAuthId !== undefined && line.certAuthId.length > 0 ? ', ' + line.certAuthId : '')}`
}

export enum LineCheckType {
  SELLERS_JSON,
  ADS_TXT
}

export function lineStatusLevelToStatusType (level: AdstxtStatusLevel | undefined): AdstxtStatus {
  switch (level) {
    case AdstxtStatusLevel.OK: return AdstxtStatus.OK
    case AdstxtStatusLevel.WARNING: return AdstxtStatus.WARNING
    case AdstxtStatusLevel.ERROR: return AdstxtStatus.ERROR
    case AdstxtStatusLevel.INFO: return AdstxtStatus.INFO
    default: return AdstxtStatus.UNKNOWN
  }
}

export function adstxtLineStatusType (adstxt: Adstxt): AdstxtStatus {
  switch (adstxt.adstxtLine.adstxtLineType) {
    case AdstxtLineType.ADAGIO:
      switch (adstxt.adstxtStatusCheck) {
        case AdstxtLineStatus.NOT_CRAWLED:
        case AdstxtLineStatus.NOT_FOUND:
          return AdstxtStatus.ERROR
        case AdstxtLineStatus.CERT_EMPTY:
        case AdstxtLineStatus.CERT_PRESENT:
        case AdstxtLineStatus.NOT_DIRECT:
        case AdstxtLineStatus.NOT_RESELLER:
        case AdstxtLineStatus.UNKNOWN_RELATIONSHIP:
          return AdstxtStatus.WARNING
        default: return AdstxtStatus.OK
      }
    case AdstxtLineType.PRIMARY:
      switch (adstxt.adstxtStatusCheck) {
        case AdstxtLineStatus.NOT_CRAWLED:
        case AdstxtLineStatus.NOT_FOUND:
        case AdstxtLineStatus.UNKNOWN_RELATIONSHIP:
        case AdstxtLineStatus.CERT_EMPTY:
        case AdstxtLineStatus.CERT_PRESENT:
          return AdstxtStatus.ERROR
        default: return AdstxtStatus.OK
      }
    case AdstxtLineType.SECONDARY:
      switch (adstxt.adstxtStatusCheck) {
        case AdstxtLineStatus.OK:
          return AdstxtStatus.OK
        default: return AdstxtStatus.INFO
      }
    case AdstxtLineType.OWNER:
    case AdstxtLineType.MANAGER:
      switch (adstxt.adstxtStatusCheck) {
        case AdstxtLineStatus.NOT_CRAWLED:
        case AdstxtLineStatus.NOT_FOUND:
        case AdstxtLineStatus.UNKNOWN_RELATIONSHIP:
        case AdstxtLineStatus.CERT_EMPTY:
        case AdstxtLineStatus.CERT_PRESENT:
        case AdstxtLineStatus.NOT_DIRECT:
        case AdstxtLineStatus.NOT_RESELLER:
          return AdstxtStatus.ERROR
        case AdstxtLineStatus.MISSING_MANAGER_ACCOUNT_ID:
          return AdstxtStatus.WARNING
      }
      return AdstxtStatus.OK
    case AdstxtLineType.OWNERDOMAIN:
    case AdstxtLineType.MANAGERDOMAIN:
      switch (adstxt.adstxtStatusCheck) {
        case AdstxtLineStatus.NOT_CRAWLED:
        case AdstxtLineStatus.NOT_FOUND:
        case AdstxtLineStatus.DOMAIN_MISMATCH:
          return AdstxtStatus.WARNING
        default: return AdstxtStatus.OK
      }
    default: return AdstxtStatus.OK
  }
}

// ------------------------------------------------------------------------------------------------------------

export function createSeatPendingLines (adstxts: AdstxtWithStatus[], optinAdstxts: OptinAdstxt[] | undefined): AdstxtWithStatus[] {
  return optinAdstxts!
    .filter(o => !adstxts.find(a => o.adstxtLineId === a.adstxtLineId))
    .map((a) => ({
      ...a,
      adstxtStatusCheck: AdstxtLineStatus.OK,
      sellersjsonStatusCheck: SellersjsonEntryStatus.OK,
      status: AdstxtStatus.PENDING,
      adstxtCheck: {
        status: AdstxtLineStatus.OK,
        statusLevel: AdstxtStatusLevel.OK,
        statusLevelAgg: AdstxtStatusLevel.OK
      }
    }))
}

export function adstxtsWithStatus (adstxts: Adstxt[], version: AdstxtVersion = ADSTXT_VERSION_1_0): AdstxtWithStatus[] {
  const adstxt11LineTypes = [
    AdstxtLineType.OWNERDOMAIN,
    AdstxtLineType.OWNER,
    AdstxtLineType.MANAGERDOMAIN
  ]

  const result = adstxts.filter(a => {
    if (version === ADSTXT_VERSION_1_0 && adstxt11LineTypes.includes(a.adstxtLine.adstxtLineType)) {
      return false
    }

    return true
  }).map(a => ({ ...a, status: version === ADSTXT_VERSION_1_0 ? adstxtLineStatusType(a) : lineStatusLevelToStatusType(a.adstxtCheck?.statusLevelAgg) }))

  // It is computed on the backend side for adstxt 1.1.
  if (version === ADSTXT_VERSION_1_0) {
    // If at least one primary line is valid, then the issues with other lines are only warnings
    const primary = result.filter(a => a.adstxtLine.adstxtLineType === AdstxtLineType.PRIMARY)
    if (primary.some(a => a.status === AdstxtStatus.OK)) {
      primary.filter(a => a.status === AdstxtStatus.ERROR).forEach(a => {
        a.status = AdstxtStatus.WARNING
      })
    }

    // If at least one manager line is valid, then the issues with other lines are only warnings
    const managerLines = result.filter(a => a.adstxtLine.adstxtLineType === AdstxtLineType.MANAGER)
    if (managerLines.some(a => a.status === AdstxtStatus.OK)) {
      managerLines.filter(a => a.status === AdstxtStatus.ERROR).forEach(a => {
        a.status = AdstxtStatus.WARNING
      })
    }
  }

  return result
}

export function sellersjsonsWithStatus (adstxts: Adstxt[], lineType: AdstxtLineType): AdstxtWithStatus[] {
  const result = adstxts.filter(a => a.adstxtLine.adstxtLineType === lineType)
    .map(a => ({ ...a, status: lineStatusLevelToStatusType(a.adstxtSellersjsonCheck?.statusLevelAgg) }))

  return result
}

export function buildAdstxtLines (adapterForm?: CreateAdapterForm, seatForm?: SeatForm, adstxtOptouts?: AdstxtOptoutForm[]): AdstxtLine[] {
  const lines: AdstxtLine[] = []

  if (adapterForm && seatForm) {
    // Primary line
    adapterForm.adstxtLinesTemplate.forEach((lineTemplate: any) => {
      const line = Object.assign({}, lineTemplate)
      for (const parameter in seatForm.configuration) {
        for (const property in line) {
          if (seatForm.configuration[parameter] !== '') {
            line[property] = line[property].replaceAll(`{${parameter}}`, seatForm.configuration[parameter])
          }
        }
      }
      // Enriching line with seat and adapter
      line.seat = seatForm
      line.adapter = adapterForm
      line.adapterId = seatForm.adapterId

      // Line subscription
      line.status = true

      lines.push(line)
    })

    // Secondary lines
    adapterForm.adstxtLines.forEach((line: any) => {
      // Enriching line with adapter
      line.adapter = adapterForm
      line.adapterId = seatForm.adapterId

      // Line subscription
      line.status = !adstxtOptouts?.find(optout => line.id === optout.adstxtLineId)

      lines.push(line)
    })
  }
  return lines
}

// Used to display the AdstxtLines in different CodeBlock components.
// Has most of the same logic as the function buildAdstxtItems, but operate directly on type `AdstxtLine`,
// so don't take in account the Adstxt status.
export function displayAdstxtLines (adstxtLines: AdstxtLine[], context?: AdstxtPreviewContext): string {
  const HEADER_LINE_TYPES = [
    AdstxtLineType.OWNERDOMAIN,
    AdstxtLineType.MANAGERDOMAIN,
    AdstxtLineType.OWNER,
    AdstxtLineType.MANAGER
  ]

  const headerLines: AdstxtLine[] = []
  const adagioLine: AdstxtLine[] = []
  const linesByAdapter: Map<number, AdstxtLine[]> = new Map()

  // Splitting the lines by Type.
  adstxtLines.forEach(line => {
    const lineType = line.adstxtLineType
    if (!line.status) {
      return
    }
    if ((lineType === AdstxtLineType.PRIMARY || lineType === AdstxtLineType.SECONDARY) && line.adapterId != null) {
      const adapterLines = linesByAdapter.get(line.adapterId)
      if (adapterLines) {
        adapterLines.push(line)
      } else {
        linesByAdapter.set(line.adapterId, [line])
      }
    } else if (lineType === AdstxtLineType.ADAGIO) {
      adagioLine.push(line)
    } else {
      headerLines.push(line)
    }
  })

  // Apply first level ordering.
  headerLines.sort((lineA, lineB) => {
    return HEADER_LINE_TYPES.indexOf(lineA.adstxtLineType) > HEADER_LINE_TYPES.indexOf(lineB.adstxtLineType) ? 1 : -1
  })

  const mainLinesOrder = Array.from(linesByAdapter.keys())
  mainLinesOrder.sort((a, b) => a > b ? 1 : -1)

  // Building the result ...
  let result = ''

  // Add a specific title in some context.
  switch (context) {
    case AdstxtPreviewContext.ADSTXT_11:
      result += `\n${displayAdstxt11Title()}\n`
      break
    case AdstxtPreviewContext.MANAGER_SETTINGS:
      result += `\n# ${i18n.global.t('supplyChain.rawManagerTitle')}\n`
      break
    case AdstxtPreviewContext.OWNER_SETTINGS:
      result += `\n# ${i18n.global.t('supplyChain.rawOwnerTitle')}\n`
      break
  }

  headerLines.forEach(line => {
    if (line.adstxtLineType === AdstxtLineType.OWNERDOMAIN ||
        line.adstxtLineType === AdstxtLineType.MANAGERDOMAIN) {
      result += `${line.adstxtLineType}=${line.domainName}\n`
    } else {
      result += displayAdstxtLine(line) + '\n'
    }
  })

  // Append the Adagio section, expecting a unique line.
  if (adagioLine.length > 0) {
    result += `\n${displayAdagioTitle()}\n`
    adagioLine.forEach(l => {
      result += displayAdstxtLine(l) + '\n'
    })
  }

  // Append main lines grouped by adapter IDs
  mainLinesOrder.forEach((aId) => {
    const aLines = linesByAdapter.get(aId) || []

    const primaryLines: AdstxtLine[] = []
    const secondaryLines: AdstxtLine[] = []
    aLines.forEach(l => {
      const t = l.adstxtLineType
      if (t === AdstxtLineType.SECONDARY) {
        secondaryLines.push(l)
      } else if (t === AdstxtLineType.PRIMARY) {
        primaryLines.push(l)
      }
    })

    primaryLines?.sort((a, b) => (a.id || 0) > (b.id || 0) ? 1 : -1)
    secondaryLines?.sort((a, b) => (a.id || 0) > (b.id || 0) ? 1 : -1)

    if (primaryLines.length === 1) {
      result += (primaryLines[0].seat) ? `\n# ${primaryLines[0].seat?.name}\n` : '\n'
      result += displayAdstxtLine(primaryLines[0]) + '\n'

      secondaryLines.forEach(l => {
        result += displayAdstxtLine(l) + '\n'
      })
    } else {
      // In the case of secondary lines shared by multiple seats, displaying a first section for the secondary lines then subsections for each seat.
      let isSubsection = false
      if (secondaryLines.length > 0) {
        result += `\n${displayBidderTitle(secondaryLines[0]?.adapter?.name)}\n`
        isSubsection = true
      }
      secondaryLines.forEach(l => {
        result += displayAdstxtLine(l) + '\n'
      })
      primaryLines.forEach(l => {
        if (isSubsection) {
          result += `\n## ${l.seat?.name}\n`
        } else {
          result += `\n# ${l.seat?.name}\n`
        }
        result += displayAdstxtLine(l) + '\n'
      })
    }
  })

  return result.trim()
}

// Used in AdstxtCodeBlock component to display the Adstxt lines with icons and tooltips according to the Adstxt status.
// Has most of the logic as the function displayAdstxtLines, but operate on type `AdstxtWithStatus` and
// take in account the Adstxt status by prefixing some lines with 'Pending Bidder Whitelisting' and graying them out.
// TODO: Try to combine the logic of both functions `displayAdstxtLines` and `buildAdstxtItems` in a single one. See discussion on Gitlab https://gitlab.com/adagioio/frontend/ssp-spa/-/merge_requests/622#note_1606585973
export function buildAdstxtItems (adstxts: AdstxtWithStatus[], version: AdstxtVersion = ADSTXT_VERSION_1_0, onlyErrors: boolean = false): AdstxtItem[] {
  const HEADER_LINE_TYPES = [
    AdstxtLineType.OWNERDOMAIN,
    AdstxtLineType.MANAGERDOMAIN,
    AdstxtLineType.OWNER,
    AdstxtLineType.MANAGER
  ]

  const headerLines: AdstxtWithStatus[] = []
  const adagioLine: AdstxtWithStatus[] = []
  const activeLinesByAdapter: Map<number, AdstxtWithStatus[]> = new Map()
  const pendingLinesByAdapter: Map<number, AdstxtWithStatus[]> = new Map()

  let linesToDisplay = adstxts
  if (onlyErrors) {
    linesToDisplay = adstxts.filter(adstxt => adstxt.adstxtStatusCheck !== AdstxtLineStatus.OK)
  }

  // Splitting the lines by Type and Status.
  linesToDisplay.forEach(line => {
    const lineType = line.adstxtLine.adstxtLineType

    if (HEADER_LINE_TYPES.includes(lineType)) {
      headerLines.push(line)
    } else if (lineType === AdstxtLineType.ADAGIO) {
      adagioLine.push(line)
    } else if (line.status === AdstxtStatus.PENDING) {
      const adapterLines = pendingLinesByAdapter.get(line.adapterId)
      if (adapterLines) {
        adapterLines.push(line)
      } else {
        pendingLinesByAdapter.set(line.adapterId, [line])
      }
    } else {
      const adapterLines = activeLinesByAdapter.get(line.adapterId)
      if (adapterLines) {
        adapterLines.push(line)
      } else {
        activeLinesByAdapter.set(line.adapterId, [line])
      }
    }
  })

  // Apply first level ordering.
  headerLines.sort((lineA, lineB) => {
    return HEADER_LINE_TYPES.indexOf(lineA.adstxtLine.adstxtLineType) > HEADER_LINE_TYPES.indexOf(lineB.adstxtLine.adstxtLineType) ? 1 : -1
  })

  const activeLinesOrder = Array.from(activeLinesByAdapter.keys())
  activeLinesOrder.sort((a, b) => a > b ? 1 : -1)

  const pendingLinesOrder = Array.from(pendingLinesByAdapter.keys())
  pendingLinesOrder.sort((a, b) => a > b ? 1 : -1)

  // Building the result ...
  const result: AdstxtItem[] = []

  if (headerLines.length > 0) {
    if (version === ADSTXT_VERSION_1_0) {
      result.push({ type: AdstxtItemType.MANAGER })
    } else {
      result.push({ type: AdstxtItemType.PARAMETER })
    }

    headerLines.forEach(adstxt => {
      result.push({ type: AdstxtItemType.ADSTXT, adstxt })
    })
  }

  // Append the Adagio section, expecting a unique line.
  // When only displaying the line in errors, there is no Adagio line, so we don't want to display the Adagio title.
  if (adagioLine.length > 0) {
    result.push({ type: AdstxtItemType.ADAGIO })
    adagioLine.forEach(l => {
      result.push({ type: AdstxtItemType.ADSTXT, adstxt: l })
    })
  }

  // Append active lines grouped by adapter IDs.
  activeLinesOrder.forEach(aId => {
    const lines = activeLinesByAdapter.get(aId) || []
    result.push(...genAdstxtItems(lines))
  })

  // Append "pending" lines grouped by adapter IDs.
  pendingLinesOrder.forEach(aId => {
    const lines = pendingLinesByAdapter.get(aId) || []
    result.push(...genAdstxtItems(lines))
  })

  return result
}

function genAdstxtItems (aLines: AdstxtWithStatus[]): AdstxtItem[] {
  const primaryLines: AdstxtWithStatus[] = []
  const secondaryLines: AdstxtWithStatus[] = []
  aLines.forEach(l => {
    const t = l.adstxtLine.adstxtLineType
    if (t === AdstxtLineType.SECONDARY) {
      secondaryLines.push(l)
    } else if (t === AdstxtLineType.PRIMARY) {
      primaryLines.push(l)
    }
  })
  primaryLines?.sort((a, b) => (a.adstxtLine?.id || 0) > (b.adstxtLine?.id || 0) ? 1 : -1)
  secondaryLines?.sort((a, b) => (a.adstxtLine?.id || 0) > (b.adstxtLine?.id || 0) ? 1 : -1)

  const result: AdstxtItem[] = []

  if (primaryLines.length === 1) {
    result.push({ type: AdstxtItemType.SEAT, adstxt: primaryLines[0] })
    result.push({ type: AdstxtItemType.ADSTXT, adstxt: primaryLines[0] })
    secondaryLines.forEach(l => {
      result.push({ type: AdstxtItemType.ADSTXT, adstxt: l })
    })
  } else {
    // In the case of secondary lines shared by multiple seats, displaying a first section for the secondary lines then subsections for each seat.
    let isSubsection = false
    if (secondaryLines.length > 0) {
      result.push({ type: AdstxtItemType.BIDDER, adstxt: secondaryLines[0] })
      isSubsection = true
    }
    secondaryLines.forEach(l => {
      result.push({ type: AdstxtItemType.ADSTXT, adstxt: l })
    })
    primaryLines.forEach(l => {
      if (isSubsection) {
        result.push({ type: AdstxtItemType.SUBSECTION, adstxt: l })
      } else {
        result.push({ type: AdstxtItemType.SEAT, adstxt: l })
      }
      result.push({ type: AdstxtItemType.ADSTXT, adstxt: l })
    })
  }

  return result
}

export function buildSellersjsonItems (entries: AdstxtWithStatus[], onlyErrors: boolean = false): AdstxtWithStatus[] {
  return entries.filter(entry => !onlyErrors || entry.sellersjsonStatusCheck !== SellersjsonEntryStatus.OK)
}

export function copyAdstxtItems (items: AdstxtItem[]): string {
  let result = ''
  for (const item of items) {
    switch (item.type) {
      case AdstxtItemType.PARAMETER:
        result += `\n${displayAdstxt11Title()}\n`
        break
      case AdstxtItemType.MANAGER:
        result += `\n${displayManagerTitle()}\n`
        break
      case AdstxtItemType.ADAGIO:
        result += `\n${displayAdagioTitle()}\n`
        break
      case AdstxtItemType.SEAT:
        result += `\n${displaySeatTitle(item.adstxt?.seat?.name, item.adstxt?.status)}\n`
        break
      case AdstxtItemType.SUBSECTION:
        // In case of a subsection, adding an extra `#` character in front of the Seat title.
        result += `\n#${displaySeatTitle(item.adstxt?.seat?.name, item.adstxt?.status)}\n`
        break
      case AdstxtItemType.BIDDER:
        result += `\n${displayBidderTitle(item.adstxt?.bidder?.name)}\n`
        break
      case AdstxtItemType.ADSTXT:
        if (item.adstxt !== undefined) {
          const adstxt = item.adstxt
          if (adstxt.adstxtLine.adstxtLineType === AdstxtLineType.OWNERDOMAIN ||
              adstxt.adstxtLine.adstxtLineType === AdstxtLineType.MANAGERDOMAIN) {
            result += `${item.adstxt.adstxtLine.adstxtLineType}=${item.adstxt.adstxtLine.domainName}\n`
          } else {
            result += `${displayAdstxtLine(item.adstxt?.adstxtLine)}\n`
          }
        }
        break
    }
  }
  return result.trim()
}

export function copySellersjsonItems (items: Adstxt[], domain: string, version: AdstxtVersion = ADSTXT_VERSION_1_0): string {
  const result: string[] = []
  for (const item of items) {
    let s = '{'
    s += `\n  "seller_id": "${item.adstxtLine.publisherAccountId}",`
    s += '\n  "name": "...",'
    s += `\n  "domain": "${version === ADSTXT_VERSION_1_1 ? domain : '...'}",`
    s += '\n  "seller_type": "PUBLISHER"'
    s += '\n}'
    result.push(s)
  }
  return result.join(',\n')
}

export function copySupplyChainObjectParams (items: AdstxtWithStatus[]): string {
  const result: string[] = []
  for (const item of items) {
    let s = '\n  {'
    s += `\n    "asi": "${item.adstxtLine.domainName}",`
    s += `\n    "sid": "${item.adstxtLine.publisherAccountId}",`
    s += `\n    "hp": "${item.adstxtLine.adstxtLineType === AdstxtLineType.OWNER ? '0' : '1'}"`
    s += '\n  }'
    result.push(s)
  }
  return `"nodes": [${result.length ? result.join(',') + '\n' : ''}]`
}

/**
 * adstxt11Category tries to categorize a website regarding the
 * Adagio internal ads.txt 1.1 specs.
 * See Notion page: https://www.notion.so/adagioio/Ads-txt-1-1-enforcement-5b4d135e5f864afead1ff4027988d94a?pvs=4#cb155f51a58e4215b5ebd09fa390edce
 */
export function adstxt11Category (
  publisherManagerDomain: string,
  publisherSellerType: SellerType,
  websiteRelationship?: PublisherRelationship,
  websiteDomain?: string,
  ownerLine?: OwnerLine
): Adstxt11Category {
  const st = publisherSellerType

  if (websiteRelationship === PublisherRelationship.OWNED) {
    if (domainContains(publisherManagerDomain, websiteDomain)) {
      if (st === SellerType.BOTH) {
        return Adstxt11Category.BOSD
      } else if (st === SellerType.PUBLISHER) {
        return Adstxt11Category.PSD
      }
    } else {
      if (st === SellerType.BOTH) {
        return Adstxt11Category.BOMD
      } else if (st === SellerType.PUBLISHER) {
        return Adstxt11Category.PMD
      }
    }
  } else if (websiteRelationship === PublisherRelationship.MANAGED) {
    if (domainContains(ownerLine?.sellersjsonDomain, websiteDomain)) {
      switch (st) {
        case SellerType.INTERMEDIARY:
          return Adstxt11Category.ISD
        case SellerType.BOTH:
          return Adstxt11Category.BMSD
      }
    } else {
      switch (st) {
        case SellerType.INTERMEDIARY:
          return Adstxt11Category.IMD
        case SellerType.BOTH:
          return Adstxt11Category.BMMD
      }
    }
  }

  return Adstxt11Category.UNKNOWN
}

export function websiteStatusBadgeTheme (status: WebsiteStatus | null | undefined): BadgeTheme {
  switch (status) {
    case WebsiteStatus.LOADING:
      return BadgeTheme.INFO
    case WebsiteStatus.VALID:
    case WebsiteStatus.READY:
      return BadgeTheme.SUCCESS
    case WebsiteStatus.INACTIVE:
    case WebsiteStatus.PENDING:
    case WebsiteStatus.ARCHIVED:
      return BadgeTheme.BASE
    case WebsiteStatus.BYPASSED:
    case WebsiteStatus.INFO:
      return BadgeTheme.INFO
    case WebsiteStatus.WARNING:
      return BadgeTheme.WARNING
    case WebsiteStatus.ERROR:
      return BadgeTheme.DANGER
    default: return BadgeTheme.BASE
  }
}

export function websiteStatusBadgeTheme11 (status: WebsiteStatus | null | undefined): BadgeTheme {
  switch (status) {
    case WebsiteStatus.LOADING:
      return BadgeTheme.INFO_11
    case WebsiteStatus.VALID:
    case WebsiteStatus.READY:
      return BadgeTheme.SUCCESS_11
    case WebsiteStatus.INACTIVE:
    case WebsiteStatus.PENDING:
    case WebsiteStatus.ARCHIVED:
      return BadgeTheme.BASE_11
    case WebsiteStatus.BYPASSED:
    case WebsiteStatus.INFO:
      return BadgeTheme.INFO_11
    case WebsiteStatus.WARNING:
      return BadgeTheme.WARNING_11
    case WebsiteStatus.ERROR:
      return BadgeTheme.DANGER_11
    default: return BadgeTheme.BASE_11
  }
}
