import { AxiosResponse } from 'axios'

import { Paginate } from '@/types/paginate'
import { ObjectLike } from '@/types/utils'

import { BulkUpdateForm } from '@/models/bulk'
import { SeatType } from '@/models/enum/seatType'
import { Seat, SeatForm, SeatWithDFP, SelectSeat } from '@/models/seat'
import { WhitelistsWithBidderLinesStatusSeat } from '@/models/whitelist'

import api from '@/api'

import { fetchForSelectFilters, FilterResponse } from './datatableFilters'

export async function createSeat (data: SeatForm): Promise<AxiosResponse<Seat>> {
  return api.post<Seat>('/seats', formatData(data), { requireContext: true })
}

/**
 * fetchSeats fetch both "Direct" and "Shared" seats for the publisher.
 */
export async function fetchSeats (queryParams?: ObjectLike): Promise<AxiosResponse<Paginate<Seat[]>>> {
  return api.get<Paginate<Seat[]>>('/seats', { requireContext: true, params: queryParams })
}

export async function fetchSeatsDFP (queryParams?: ObjectLike): Promise<AxiosResponse<Paginate<SeatWithDFP[]>>> {
  return api.get<Paginate<SeatWithDFP[]>>('/seats/dfp', { params: queryParams })
}

export async function fetchSeatsForPublisher (publisherId: number, queryParams?: ObjectLike): Promise<AxiosResponse<Paginate<Seat[]>> | AxiosResponse<null>> {
  if (!publisherId || publisherId === 0) { // Prevent the request from being sent if the publisherId is not set
    return {} as AxiosResponse<null>
  }
  return api.get<Paginate<Seat[]>>(`/publishers/${publisherId}/seats`, { params: queryParams })
}

export async function fetchSeat (id: number): Promise<AxiosResponse<Seat>> {
  return api.get<Seat>(`/seats/${id}`, { requireContext: true })
}

export async function updateSeat (id: number, data: SeatForm): Promise<AxiosResponse<Seat>> {
  return api.patch<Seat>(`/seats/${id}`, formatData(data), { requireContext: true })
}

function formatData (data: SeatForm): Record<string, any> {
  const payload: Record<string, any> = { ...data }
  delete payload.adstxtLines
  delete payload.discrepanciesBidAdjustment
  delete payload.ivtBidAdjustment

  if (payload.commissions && typeof payload.commissions === 'string') {
    payload.commissions = JSON.parse(data.commissions.toString())
  }

  if (payload.configuration && typeof payload.configuration === 'string') {
    payload.configuration = JSON.parse(data.configuration.toString())
  }

  return payload
}

export async function updateSeatBulk (data: BulkUpdateForm): Promise<AxiosResponse<void>> {
  return api.patch('/seats/bulk', data, { requireContext: true })
}

export async function updateSeatDFPBulk (data: BulkUpdateForm): Promise<AxiosResponse<void>> {
  return api.patch('/seats/dfp', data)
}

export async function fetchSeatsForSelectFilters (field: string, query: object): Promise<AxiosResponse<FilterResponse[]>> {
  return fetchForSelectFilters('/seats', field, query)
}

// fetchWhitelists returns a subset of the `whitelists` infos and the BidderLinesStatus (aggregation of adstxt_checks.status_level_agg).
export async function fetchWhitelistsWithBidderLinesStatus (seatId: string, queryParams?: any): Promise<AxiosResponse<Paginate<WhitelistsWithBidderLinesStatusSeat[]>>> {
  return api.get<Paginate<WhitelistsWithBidderLinesStatusSeat[]>>(`/seats/${seatId}/whitelists`, { requireContext: true, params: queryParams })
}

export async function fetchSeatsForSelect (seatType?: SeatType, search?: string, ids?: Array<string | number>, withFilters?: string[]): Promise<AxiosResponse<SelectSeat[]>> {
  const filter: string[] = withFilters || []
  if (seatType) {
    filter.push(`seat_type||$eq||${seatType}`)
  }
  if (ids?.length) {
    filter.push(`id||$in||${ids.join(',')}`)
  }
  return api.get<SelectSeat[]>('/seats', { params: { search, filter } })
}
